/* Resetting default margins and paddings */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Arial', sans-serif;
}

/* Ensuring the root of your app stretches to fill the space */
#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-Background {
  /* Set the imported image as the background */
  background-image: url('../images/edin_background8.jpg');
  /* Set the size of the background image to cover the entire element */
  background-size: cover;
  /* Position the background image to the center */
  background-position: center;
  /* Set the height to 100% of the viewport height */
  background-blend-mode: overlay; /* This blends the color with the image, can be adjusted */
  height: 100vh;
  /* Make sure the width spans the entire screen */
  width: 100%;
  /* Fix the background image position (so it won't scroll) */
  background-attachment: fixed;
  /* No repeat of the background image */
  background-repeat: no-repeat;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}


.App-header p {
  margin: 20px auto; /* Vertical margin of 20px, auto horizontal margin to center the text */
  max-width: 50%; /* Taking 80% of its container's width. Adjust based on your preference. */
  text-align: justify;
  font-size: calc(30% + 1vw); /* Adjusted font size for responsiveness */
  padding: 0 2%; /* Optional: Added a little padding on the sides for smaller screens */
}

.App-link {
  color: #61dafb;
}

/* For tablets */
@media (max-width: 768px) {
  .App-header p {
    font-size: calc(30% + 2vw);
    max-width: 60%;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .App-header p {
    font-size: calc(30% + 2.5vw);
    max-width: 60%;
    margin: 10px auto; /* Reduce the vertical margin for smaller screens */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
