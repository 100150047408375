.countdown {
    padding: 20px;
    grid-area: countdown;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(50px, 150px));
    grid-template-rows: 1fr;
    grid-gap: 10px;
    justify-content: center;
    border-bottom: 1px solid var(--black);
    
  }
  
  .countdown-segment {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    justify-items: center;
  }
  
  .countdown-segment-number {
    font-family: 'Arial', sans-serif;
    font-size: 6vmin;
    font-weight: 100;
  }
  
  .countdown-segment-caption {
    font-size: 16px;
  }
  
  @media (max-width: 700px) {
    .countdown {
      grid-gap: 0;
    }
    .countdown-segment-number {
      font-size: 8vmin;
    }
  }